/**
 * 日期格式化
 */
export function formatter(time, fmt) {
    let $this = new Date(time)
    let o = {
        'M+': $this.getMonth() + 1,
        'd+': $this.getDate(),
        'h+': $this.getHours(),
        'm+': $this.getMinutes(),
        's+': $this.getSeconds(),
        'q+': Math.floor(($this.getMonth() + 3) / 3),
        'S': $this.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ($this.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt
}


/**
 * 动态设置html的文字大小
*/
export function htmlFontSize() {
    var ui_w = 750;
    // 获取屏幕的宽度
    var clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

    // 通过js动态改变html根节点字体大小
    var html_ = document.getElementsByTagName('html')[0];
    html_.style.fontSize = (clientWidth/ui_w)*100 +'px';
}

/**
 * 判断是手机还是pc
*/
export function isMobile() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPad|iPod|iPhone|ios|Android|Mobile|IEMobile|BlackBerry|JUC|Fennec|wOSBrowser|WebOS|MQQBrowser|BrowserNG|Symbian|Windows Phone)/i))) {
        return true

    }else{
        return false
    }
}

/**
 * 判断是否弹出产品亮点弹窗
 */
export let showHighlightsModal = true;
export function setShowHighlightsModal() {
    showHighlightsModal = false
}
