import request from "@/util/request";

/**
 * 获取资讯列表
*/
export const getArticleList = (params) => {
  return request({
      url: "/new_gateway/codepower-rest-api/article/list",
    method: "get",
    params
  });
};

/**
 * 获取资讯详情
*/
export const getArticleDetails = (id) => {
  return request({
      url: "/new_gateway/codepower-rest-api/article/detail/" + id,
    method: "get",
  });
};
