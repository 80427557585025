<template>
  <div :class="isMobile ? 'information-mobile' : 'information'">
    <div class="container">
      <!-- <el-card class="mr-btm" shadow="never" :style="{margin: '16px 0'}">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input placeholder="请输入内容">
              <template slot="prepend">标题</template>
            </el-input>
          </el-col>
          <el-col :span="6">
            <div class="custom-select">
              <div class="select-title">企业/媒体</div>
              <el-select
                v-model="from.origin"
              >
                <el-option label="餐厅名" value="1"></el-option>
                <el-option label="订单号" value="2"></el-option>
                <el-option label="用户电话" value="3"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="custom-select">
              <div class="select-title">企业/媒体</div>
              <el-select
                v-model="from.origin"
              >
                <el-option label="餐厅名" value="1"></el-option>
                <el-option label="订单号" value="2"></el-option>
                <el-option label="用户电话" value="3"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-card> -->
      <el-card shadow="never">
        <!-- <el-tabs v-model="editableTabsValue" type="card" @tab-click="tabClickHandle">
          <el-tab-pane
            :key="item.name"
            v-for="item in editableTabs"
            :label="item.title"
            :name="item.name"
          >
            {{item.content}}
          </el-tab-pane>
        </el-tabs> -->
        <!-- <div class="page-data">
          第 {{ pagination.startRow }} - {{ pagination.endRow }} 条，共 {{ pagination.total }} 条记录
        </div> -->
        <div class="infinite-list-wrapper" style="overflow:auto">
          <div 
            class="list"
          >
            <div 
              v-for="item in informationList"
              :key="item.id"
              class="list-item">
              <div class="left">
                <div class="banner-box">
                  <img :src="item.image" alt="">
                </div>
              </div>
              <div class="right">
                <div class="r-header">
                  <span class="title" @click="() => viewDetails(item)">{{ item.title }}</span>
                  <!-- <span>
                    <a :href="item.orgUrl">查看原文</a>
                  </span> -->
                </div>
                <div class="r-describe">
                  {{ item.description.length > 120 ? item.description.slice(0, 120) + '...' : item.description }}
                </div>
                <div class="r-footer">
                  <div class="tags" v-if="item.keywords">
                    <span class="tag-item" v-for="tag in item.keywords.split(',')" :key="tag">{{tag}}</span>
                  </div>
                  <div class="meta">
                    <span class="info-pos"><i class="el-icon-location"></i>{{ item.source }}</span>
                    <span class="info-time"><i class="iconfont-new icon-31shijian"></i>{{ item.publishTime ? moment(item.publishTime).format('YYYY-MM-DD') : item.publishTime }}</span>
                    <div class="info-right">
                      <span :style="{marginRight: '10px'}"><i class="iconfont-new icon-yanjing"></i> {{ item.clickCount }}</span>
                      <span><i class="iconfont-new icon-dianzan"></i> {{ item.likeCount }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="test-center" v-if="loading">加载中...</p>
        <p class="test-center" v-if="noMore">没有更多了</p>
        <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="from.currentPage"
        :page-sizes="[5, 15, 30, 45]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination> -->
      </el-card>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { isMobile } from '../../util/common'
import { getArticleList } from '../../api/acticle'
export default {
  data() { 
    return {
      moment,
      editableTabsValue: '1',
      // editableTabs: [{
      //   title: '全部',
      //   name: '1',
      // }, {
      //   title: '处理中',
      //   name: '2',
      // }, {
      //   title: '待发布',
      //   name: '3',
      // }, {
      //   title: '已发布',
      //   name: '4',
      // }, {
      //   title: '已撤销',
      //   name: '5',
      // }],
      pagination: {
        total: 0,
        startRow: 0,
        endRow: 0,
      },
      from: {
        // status: 1,
        pageNum: 1,
        pageSize: 15,
      },
      informationList: [],
      loading: false,
      isMobile: false,
      scroll: 0,
    }
  },
  async activated() {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false
      window.addEventListener('scroll', this.scrollLoad, true)
    } else { 
      this.isMobile = isMobile()
      window.addEventListener('scroll',this.scrollLoad, true)
      this.getInformationList()
    }
    setTimeout(() => { 
      if (this.scroll > 0) { 
        window.scrollTo(0, this.scroll);
      }
    }, 0)
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'informationDetails') {
      to.meta.isBack = true
    } else { 
      to.meta.isBack = false
    }
    next()
  },
  deactivated() {
    window.removeEventListener("scroll", this.scrollLoad, true);
  },
  // mounted() {
  //   this.isMobile = isMobile()
  //   window.addEventListener('scroll',this.scrollLoad, true)
  //   this.getInformationList()
  // },
  destroyed() {
    window.removeEventListener("scroll", this.scrollLoad, true);
  },
  computed: {
    noMore () {
      return this.informationList.length >= this.pagination.total
    },
  },
  methods: {
    scrollLoad() {
      const clientHeight = document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.scroll = scrollTop
      const scrollHeight = document.documentElement.scrollHeight;
      if (this.informationList.length >= this.pagination.total) { 
        return
      }
      if (this.loading) { 
        return
      }
      if (clientHeight + scrollTop >= scrollHeight - 20) { 
        this.loading = true
        getArticleList({
          status: this.from.status,
          pageNum: this.from.pageNum + 1,
          pageSize: this.from.pageSize 
        }).then(res => { 
          if (res.code === 200) { 
            this.informationList.push(...res.data.list)
            this.pagination.endRow = res.data.endRow
            this.from.pageNum = this.from.pageNum + 1
            this.loading = false
          }
        } )
      }
    },
    // 获取资讯列表
    getInformationList() { 
      const requestParams = {
        ...this.from
      }
      getArticleList(requestParams).then(res => { 
        if (res.code === 200) { 
          this.informationList = res.data.list
          this.pagination.total = res.data.total
          this.pagination.startRow = res.data.startRow
          this.pagination.endRow = res.data.endRow
        }
      }).catch(err => { 
        console.log(err);
      })
    },
    // 查看详情
    viewDetails(item) { 
      this.$router.push({
        path: '/information-details', query: {
        id: item.id
      } })
    },
    // tabClickHandle(tab) { 
    //   const { name } = tab
    //   this.from.status = Number(name)
    //   this.getInformationList()
    // },
    handleSizeChange(val) {
      this.from.pageSize = val
      this.getInformationList()
    },
    handleCurrentChange(val) {
      this.from.pageNum = val
      this.getInformationList()
    }
  },
}
</script>

<style lang="scss" scoped>
// @media (max-width: 1024px) {
  .information-mobile {
    box-sizing: border-box;
    height: 100%;
    width: 100vw;
    padding-top: 0.88rem;
    .container {
      width: 100%;
    }
    .test-center {
      text-align: center;
    }
    .list {
      .list-item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // height: 4rem;
        padding: .2rem 0;
        border-bottom: 1px solid #dee2e6;
        .left {
          padding-bottom: .2rem;
          .banner-box {
            overflow: hidden;
            width: 100%;
            height: 4rem;
            border-radius: .10rem;
            img {
              // height: 100%;
              vertical-align: middle;
              width: 100%;
            }
          }
        }
        .right {
          width: 100%;
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          .r-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: .06rem;
            .title {
              font-size: .3rem;
              font-weight: 600;
              cursor: pointer;
              &:hover {
                color: #4850FF;
              }
            }
          }
          .r-footer {
            padding-top: .2rem;
            width: 100%;
            .tags {
              .tag-item {
                display: inline-block;
                padding: .025rem .04rem;
                margin-right: .1rem;
                margin-bottom: .1rem;
                background-color: #f8f9fa;
                border-radius: .04rem;
              }
            }  
            .meta {
              display: flex;
              justify-content: space-between;
              padding-top: .06rem;
            }     
          }
        }
      }
    }
  }
// }

// @media (min-width: 1024px) {
  .information {
    box-sizing: border-box;
    height: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    .test-center {
      text-align: center;
    }
    .custom-select {
      line-height: normal;
      display: inline-table;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      .select-title {
        display: table-cell;
        background-color: #F5F7FA;
        color: #909399;
        vertical-align: middle;
        display: table-cell;
        position: relative;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        padding: 0 20px;
        width: 1px;
        white-space: nowrap;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      /deep/.el-select {
        .el-input {
          .el-input__inner {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
    .mr-btm {
      margin-bottom: 30px;
    }
    .list {
      .list-item {
        box-sizing: border-box;
        display: flex;
        height: 160px;
        padding: 10px 0;
        border-bottom: 1px solid #dee2e6;
        .left {
          .banner-box {
            overflow: hidden;
            width: 200px;
            height: 140px;
            border-radius: 10px;
            img {
              height: 100%;
            }
          }
        }
        .right {
          width: 100%;
          margin-left: 20px;
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          .r-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 6px;
            .title {
              font-size: 20px;
              font-weight: 600;
              cursor: pointer;
              &:hover {
                color: #4850FF;
              }
            }
          }
          .r-footer {
            width: 100%;
            position: absolute;
            bottom: 0; 
            .tags {
              .tag-item {
                padding: 2.5px 4px;
                margin-right: 8px;
                background-color: #f8f9fa;
                border-radius: 4px;
              }
            }  
            .meta {
              display: flex;
              justify-content: space-between;
              padding-top: 6px;
            }     
          }
        }
      }
    }
  }
// }


</style>

